import React, { useEffect } from 'react'
import styled from 'styled-components'
import { COLORS, SIZES } from '../../styles/theme'
import config from '../../config'
import bannerL from '../../images/backdrops/event-banner.png'
import bannerP from '../../images/backdrops/banner.png'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.postregister.landscape});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  color: #fff;
  padding: 2em 15em;
  padding-bottom: 4.5em;

  #message {
    width: 90%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    padding: 1rem;
    text-align: left;
    .icon {
      width: 100px;
      margin: 0 auto;
    }
    h5 {
      font-weight: 600;
      font-size: 24px;
      margin-left: 16px;
    }
  }
  .mobile-hide {
    display: block;
  }
  .mobile-show {
    display: none;
  }
  ${SIZES.mobile} {
    padding: 2em 0;
    background-image: url(${config.assets.postregister.portrait});
    .mobile-hide {
      display: none;
    }
    .mobile-show {
      display: block;
    }
    #message {
      flex-direction: column;
      width: 100%;
      padding: 0 2.5rem;
      text-align: center;
      h5 {
        font-size: 1.2em;
        margin-left: 0;
      }
    }
  }
  
`

export default function PostRegisterPage() {
  useEffect(() => {
    document.title = `${config.eventName} | Registration`
  }, [])
  
  return (
    <Page>
      <div>
        <img width="100%" src={bannerL} alt="event-banner" className="mx-auto mb-5 mobile-hide" />
        <img width="100%" src={bannerP} alt="event-banner" className="mx-auto mb-5 mobile-show" />
        <div id="message" className="">
          <div className="icon">
            <svg width="92.936" height="92.936" viewBox="0 0 92.936 92.936">
              <path d="M48.468,2A46.468,46.468,0,1,0,94.936,48.468,46.485,46.485,0,0,0,48.468,2Zm0,83.643A37.174,37.174,0,1,1,85.643,48.468,37.224,37.224,0,0,1,48.468,85.643ZM66.5,31.228,39.174,58.552l-8.736-8.736a4.633,4.633,0,0,0-6.552,6.552L35.922,68.4a4.628,4.628,0,0,0,6.552,0L73.1,37.78a4.628,4.628,0,0,0,0-6.552,4.684,4.684,0,0,0-6.6,0Z" transform="translate(-2 -2)" fill="#FAD60D"/>
            </svg>
          </div>
          <h5>
            Thank you for your registration and you will receive a confirmation email within 3 days after your registration.
          </h5>
        </div>
      </div>
    </Page>
  )
}
