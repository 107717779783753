import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { COLORS, SIZES } from '../../styles/theme';
import config from '../../config'
import routes from '../../routes'
import bannerL from '../../images/backdrops/event-banner.png'
import bannerP from '../../images/backdrops/banner.png'
import { toast } from 'react-toastify';

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.postregister.landscape});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  .mobile-hide {
    display: block;
  }
  .mobile-show {
    display: none;
  }
  ${SIZES.mobile} {
    background-image: url(${config.assets.postregister.portrait});
    background-position: center;
    font-size: 70%;
    .mobile-hide {
      display: none;
    }
    .mobile-show {
      display: block;
    }
  }
  #content {
    color: ${COLORS.primary};
 
    #header {
      width: 100%;
      margin: 48px 0;
      img {
        margin: 0 auto;
        width: 60%;
      }
    }
    ${SIZES.mobile} {
      height: 100%;
      #header img {
        width: 100%;
      }
    }
  }
  #action-button {
    width: 50%;
    margin: 0 auto;
    ${SIZES.mobile} {
      width: 100%; 
    }
    a,
    button {
      background: #CE2440;
      border: 2px solid #fff;
      width: 45%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-bottom: 50px;
      font-size: 1.2em;
      color: #fff;
      font-weight: 500;
      padding: 8px 28px;
      border-radius: 56px;
      font-style: italic ;

      div {
        line-height: 32px;
      }
      div.arrow {
        line-height: 24px;
        font-size: 1.4em;
        align-self: flex-end;
      }
      ${SIZES.mobile} {
        font-size: 1.25em;
        width: 65%;
      }
    }
  }
`

export default function PreRegisterPage() {
  const handleMessage = () => {
    toast("Registration is now closed.")
  }
  return (
    <Page>
      <div id="content">
        <div className="h-100 d-flex justify-content-between flex-wrap">
          <div id="header" className="align-self-end">
            <img src={bannerL} alt="event-banner" className="mobile-hide" />
            <img src={bannerP} alt="event-banner" className="mobile-show" />
          </div>
          <div id="action-button" className="align-self-end">
            <Button variant="default" as={Link} to={routes.register}>
              <div>REGISTER HERE</div>
              <div className="arrow">⇀</div>
            </Button>
          </div>
        </div>
      </div>
    </Page>
  )
}