import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import config from '../config'
import { useDispatch, useSelector } from 'react-redux'
import { getBooths } from '../ducks/booth'
import Amplitude from '../utils/amplitudeHelper'
import banner from '../images/backdrops/hall-banner.png'
import { SIZES } from '../styles/theme'
import { Row, Col } from 'react-bootstrap'
import { usePageActiveUserCount } from '../utils/activeUserCount'

const Page = styled.div`
  overflow: hidden;
  background-image: ${(props)=> `url(${props.hallBg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  padding-top: 30px;

  #banner {
    position: fixed;
    top: calc(${SIZES.navHeight} - 8px);
    height: 100%;
    width: 100%;
    img {
      height: 17.5%;
      /* width: 80%; */
      display: block;
      margin: 0 auto;
    }
  }
  #hall {
    position: fixed;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding: 150px 0px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    height: 100%;
  }
  .booths {
    display: block;
    margin: 20px auto;
    margin-top: 20px;
    /* height: 23vh; */
    width: 100%;
    max-width: 220px;
    -webkit-transition: margin 0.2s ease-out;
    -moz-transition: margin 0.2s ease-out;
    -o-transition: margin 0.2s ease-out;

    &:hover {
      cursor:pointer;
      margin-top: 5px;
    }
  }
  @media screen and (min-width: 1280px) {
    #hall {
      width: 75%;
      .booths {
        max-width: 240px;
      }
    }
  }
  @media screen and (min-width: 1440px) {
    #hall {
      .booths {
        max-width: 280px;
      }
    }
  }
  @media screen and (min-width: 1960px) {
    #hall {
      .booths {
        max-width: 360px;
      }
    }
  }
`

export default function ExhibitionPage({
  hallBg,
  hallNumber,
  currentUser,
}) {

  const dispatch = useDispatch()
  const { booths } = useSelector((state) => state.booth)
  
  usePageActiveUserCount(currentUser)
  
  useEffect(() => {
    dispatch(getBooths(hallNumber))
  }, [hallNumber, dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Exhibition Hall`
  }, [])

  useEffect(() => {
    Amplitude.visit('Exhibition Hall')
  }, [currentUser.id])

  return (
    <Page hallBg={hallBg} >
      <div id="banner">
        <img src={banner} alt="event-banner" />
      </div>
      <div id="hall">
        <Row noGutters>
          {booths.map((booth,index) => {
            return (
              <>
                <Col md={3}>
                  <Link to={`/booths/${booth.id}`}>
                    <img className="booths" src={booth.hall_image.url} alt="booth" />
                  </Link>
                </Col>
              </>
            )
          })}
        </Row>
      </div>
    </Page>
  )
}
