import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../styles/theme'
import KfairAgenda from './KfairAgenda'

const SideNav = styled.div`
  #sidebar {
    display: ${(props) => (props.open ? 'block' : 'none')};
    width: 420px;
    max-width: 100vw;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    background: linear-gradient(45deg, #1b083f, #1a083f, #413273);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    overflow-x: hidden;

    .close {
      position: absolute;
      z-index: 55;
      background: transparent;
      border: none;
      outline: none;
      color: white;
      transition: 200ms;
      ${SIZES.rightBelowNoticeBar(9)}
      right: 20px;
      opacity: 1;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      text-shadow: none;
    }
    .agenda {
      color: white;
      padding: 48px 18px;
      padding-right: 12px;
    }
  }

  ${SIZES.mobile} {
    #sidebar {
      width: 100vw;
      .agenda {
        max-width: 540px;
        margin: 0 auto;
      }
    }
  }
`

export default function SideAgenda({ open, toggleSidebar, hallNumber, currentUser }) {
  return (
    <SideNav open={open} hallNumber={hallNumber}>
      <div id="sidebar">
        <button onClick={toggleSidebar} className="close">
          {open && (
            <>
              <small>Back</small>
              <svg width="49" height="49" viewBox="0 0 49 49">
                <defs>
                  <filter id="Ellipse_84" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse">
                    <feOffset input="SourceAlpha"/>
                    <feGaussianBlur stdDeviation="1.5" result="blur"/>
                    <feFlood floodColor="#270d48" floodOpacity="0.533"/>
                    <feComposite operator="in" in2="blur"/>
                    <feComposite in="SourceGraphic"/>
                  </filter>
                </defs>
                <g data-name="Group 24601" transform="translate(-4485.5 -1196.5)">
                  <g transform="matrix(1, 0, 0, 1, 4485.5, 1196.5)" filter="url(#Ellipse_84)">
                    <g transform="translate(4.5 4.5)" fill="#ce2440" stroke="#fff" strokeWidth="2">
                      <circle cx="20" cy="20" r="20" stroke="none"/>
                      <circle cx="20" cy="20" r="19" fill="none"/>
                    </g>
                  </g>
                  <g transform="translate(4515.301 1231.634) rotate(180)">
                    <path d="M.353,11.119,10.107,20.3a1.258,1.258,0,0,0,1.7,0l1.138-1.071a1.088,1.088,0,0,0,0-1.6L5.22,10.317,12.95,3.005a1.088,1.088,0,0,0,0-1.6L11.811.332a1.258,1.258,0,0,0-1.7,0L.353,9.515A1.088,1.088,0,0,0,.353,11.119Z" transform="translate(13.301 20.634) rotate(180)" fill="#fff"/>
                  </g>
                </g>
              </svg>
            </>
          )}
        </button>
        <div className="agenda h-100">
          <KfairAgenda currentUser={currentUser} />
        </div>
      </div>
    </SideNav>
  )
}
