import thumb1 from '../images/gallerium/thumbnails/1.png'
import thumb2 from '../images/gallerium/thumbnails/2.png'
import thumb3 from '../images/gallerium/thumbnails/3.png'
import thumb4 from '../images/gallerium/thumbnails/4.png'
import thumb5 from '../images/gallerium/thumbnails/5.png'
import thumb6 from '../images/gallerium/thumbnails/6.png'
import thumb7 from '../images/gallerium/thumbnails/7.png'
import thumb8 from '../images/gallerium/thumbnails/8.png'
import thumb9 from '../images/gallerium/thumbnails/9.png'
import thumb10 from '../images/gallerium/thumbnails/10.png'
import thumb11 from '../images/gallerium/thumbnails/11.png'
import thumb12 from '../images/gallerium/thumbnails/12.png'
import thumb13 from '../images/gallerium/thumbnails/13.png'
import thumb14 from '../images/gallerium/thumbnails/14.png'
import thumb15 from '../images/gallerium/thumbnails/15.png'


export default [
  {
    id: 1,
    videoId: '525483935',
    link:'https://player.vimeo.com/video/525483935',
    image: thumb1,
  },
  {
    id: 2,
    videoId: '525484371',
    link:'https://player.vimeo.com/video/525484371',
    image: thumb2,
  },
  {
    id: 3,
    videoId: '525484444',
    link:'https://player.vimeo.com/video/525484444',
    image: thumb3,
  },
  {
    id: 4,
    videoId: '525485381',
    link:'https://player.vimeo.com/video/525485381',
    image: thumb4,
  },
  {
    id: 5,
    videoId: '525485539',
    link:'https://player.vimeo.com/video/525485539',
    image: thumb5,
  },
  {
    id: 6,
    videoId: '525485589',
    link:'https://player.vimeo.com/video/525485589',
    image: thumb6,
  },
  {
    id: 7,
    videoId: '525485645',
    link:'https://player.vimeo.com/video/525485645',
    image: thumb7,
  },
  {
    id: 8,
    videoId: '525485700',
    link:'https://player.vimeo.com/video/525485700',
    image: thumb8,
  },
  {
    id: 9,
    videoId: '525485769',
    link:'https://player.vimeo.com/video/525485769',
    image: thumb9,
  },
  {
    id: 10,
    videoId: 'pdf',
    link:'https://drive.google.com/file/d/17Vv7ySqOlIdlq6Mbbr0wpdTZtdR1cNVU/view?usp=sharing',
    image: thumb10,
  },
  {
    id: 11,
    videoId: '525485830',
    link:'https://player.vimeo.com/video/525485830',
    image: thumb11,
  },
  {
    id: 12,
    videoId: '525483699',
    link:'https://player.vimeo.com/video/525483699',
    image: thumb12,
  },
  {
    id: 13,
    videoId: '525483778',
    link:'https://player.vimeo.com/video/525483778',
    image: thumb13,
  },
  {
    id: 14,
    videoId: 'pdf',
    link:'https://drive.google.com/file/d/1A7f5SbihpcVdmNPzRgUvj0kAcjJM0CEI/view?usp=sharing',
    image: thumb14,
  },
  {
    id: 15,
    videoId: 'pdf',
    link:'https://drive.google.com/file/d/1ubRkCGd5jzhCOvPI0TFJnNMPrZrt5as6/view?usp=sharing',
    image: thumb15,
  },
]
