export default {
  root: '/',

  preregister: '/registration',
  register: '/registration/form',
  postRegister: '/registration/complete',
  resetPassword: '/registration/create-password',
  forgotPassword: '/registration/forgot-password',
  login: '/login',

  notifications: '/notifications',
  menu: '/agenda',

  lobby: '/lobby',

  networking: '/networking-lounge',

  auditorium1: '/auditorium-hall',

  exhibition1: '/exhibition-hall-1',

  gallerium: '/gallery',
}
