import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import Axios from 'axios'
import { toast } from 'react-toastify'
import queryString from 'query-string'
import config from '../../config'
import routes from '../../routes'
import { COLORS } from '../../styles/theme'

const StyledForm = styled.form`
  background: #FFF;
  color: #404040;
  padding: 20px 10px;
  border-radius: 28px;
  margin: auto;
  margin-bottom: 32px;
  width: 85%;
  text-align: center;
  z-index: 100;

  .title {
    font-weight: 700;
    font-size:1.2em;
  }
  input {
    width: 80%;
    background: ${COLORS.lightGray};
    border: none;
    padding: 8px 16px;
    border-radius: 25px;
    display: block;
    margin: 0px auto;
    margin-top: 20px;

    &::placeholder {
      color: ${COLORS.lightGray1};
      font-size: 14px;
    }
  }
  .submit-button {
    display: block;
    margin: 0.5em auto;
    width: 80%;
    margin-top: 20px;
    font-size: 1em;
    color: white;
    padding: 4px 24px;
    border-radius: 50px;
    background: #CE2440;
    border: 2px solid #fff;
    box-shadow: 0px 5px 4px -4px #000;
    &:disabled {
      cursor: not-allowed;
      background-color: grey;
    }
  }
  small {
    font-size: 11px;
  }
  @media screen and (min-width: 540px) {
    width: 60%;
    margin-bottom: 50px;
    font-size:115%;
    z-index: 100;
    small {
      font-size: 14px;
    }
    input {
      margin-top: 28px;
      margin-bottom: 12px;
      &::placeholder {
        font-size: 16px;
      }
    }
    .submit-button {
      margin: 32px auto;
      font-size: 1.1em;
    }
  }
`

const MobileResetPassword = () => {
  const { isAuthenticated } = useSelector((state) => state.auth)
  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    Axios.post(`${config.apiUrl}/api/v1/create_password`, {
      password
    }, {
      headers: {
        Authorization: `Bearer ${queryString.parse(location.search).token}`,
      },
    })
      .then(() => {
        setLoading(false)
        toast('Your password is successfully created!')
        history.push(routes.root)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    password.length >= 8 &&
    passwordConfirmation.length &&
    password === passwordConfirmation
  )

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  }

  return (
    <StyledForm>
      <p className="title">Create New Password</p>
      <form onSubmit={handleSubmit}>
        <input
          className="form-input"
          required
          type="password"
          value={password}
          placeholder="Password(min 8 characters)"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="form-input"
          required
          type="password"
          placeholder="Password Confirmation"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
        {
          password.length < 8 && (
            <p className="text-danger text-center mb-0 mt-2">
              <em>
                <small>Your password should be min 8 characters</small>
              </em>
            </p>
          )
        }
        {hasEmptyField && (
          <p className="text-danger text-center m-0">
            <em>
              <small>Please make sure both password matches</small>
            </em>
          </p>
        )}
        <button
          type="submit"
          className="submit-button"
          disabled={hasEmptyField || loading}>
          {
            loading ?
              'Loading...' :
              'Create Password'
          }
        </button>
      </form>
    </StyledForm>
  )
}

export default MobileResetPassword
