import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyBUpBLHyuG3GaEDza69_ra_CagtS1hTZ6M',
  authDomain: 'dksh-medflix.firebaseapp.com',
  projectId: 'dksh-medflix',
  storageBucket: 'dksh-medflix.appspot.com',
  messagingSenderId: '1032958336646',
  appId: '1:1032958336646:web:e50eeeb7a34ee72b8dbb07',
  // measurementId: 'G-MYCN9XLCDX',
}

firebase.initializeApp(firebaseConfig)

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const ChatDb = firebaseDb.ref('chat')
const ChatKfairDb = firebaseDb.ref('chatAuditorium')
const ChatMcDb = firebaseDb.ref('chatMc')
const NoticeDb = firebaseDb.ref('notice')
const AccessDb = firebaseDb.ref('access')
const LoungeDb = firebaseDb.ref('lounge')
const NotificationDb = firebaseDb.ref('pushNotifications')

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  ChatKfairDb,
  ChatMcDb,
  NoticeDb,
  NotificationDb,
  AccessDb,
  LoungeDb
}
