
const getButtonPos = (boothType) => {
  switch (boothType){
    case 1:
      return {
        vid: `
          top: 45.5%;
          left: 68.5%;
        `,
        image: `
          top: 50%;
          left: 40%;
        `,
        chat: `
          top: 67.5%;
          left: 67%;
        `,
        pdf: `
          top: 43%;
          left: 88.5%;
        `,
        info: `
          top: 67%;
          left: 72%;
        `,
        namecard: `
          top: 67%;
          left: 65%;
        `,
      }
    case 2:
      return {
        vid: `
          top: 44%;
          left: 19.5%;
        `,
        image: `
          top: 38%;
          left: 58%;
        `,
        chat: `
          top: 72.5%;
          left: 25%;
        `,
        pdf: `
          top: 43%;
          left: 83.5%;
        `,
        info: `
          top: 58%;
          left: 10%;
        `,
        namecard: `
          top: 58%;
          left: 3%;
        `,
      }
    case 3:
      return {
        vid: `
          top: 45.5%;
          left: 68.5%;
        `,
        image: `
          top: 55%;
          left: 30%;
        `,
        chat: `
          top: 67.5%;
          left: 82%;
        `,
        pdf: `
          top: 65%;
          left: 62%;
        `,
        info: `
          top: 57%;
          left: 85%;
        `,
        namecard: `
          top: 57%;
          left: 93%;
        `,
      }
    case 4:
      return {
        vid: `
          top: 37%;
          left: 75%;
        `,
        image: `
          top: 65%;
          left: 30%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 69%;
          left: 51%;
        `,
        info: `
          top: 65.5%;
          left: 89.5%;
        `,
        namecard: `
          top: 65.5%;
          left: 81.5%;
        `,
        zoom: `
          top: 82.5%;
          left: 72.5%;
        `,
      }
    case 5:
      return {
        vid: `
          top: 47%;
          left: 62.5%;
        `,
        image: `
          top: 50%;
          left: 32%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 68.5%;
          left: 45.5%;
        `,
        info: `
          top: 62.5%;
          left: 80%;
        `,
        namecard: `
          top: 62.5%;
          left: 87%;
        `,
      }
    default:
      return {}
  }
}
export { getButtonPos };