import React, { useState } from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar'
import { COLORS } from '../../styles/theme'
import DropNamecardModal from './DropNamecardModal'
// import bizCard from '../../images/icons/bizCard.png'

const Page = styled.div`
  height: 100%;
  padding: 32px 24px;
  /* backdrop-filter: blur(20px); */
  background-color: rgba(255,255,255,0.75);
  font-size: 0.8em;

  .contact-card-header {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
    .contact-close {
      text-align: right;
      font-weight: bold;
      color: ${COLORS.primaryLight};
    }
    h5 {
      color: #312782;
      font-style: italic;
      font-weight: 600;
    }
  }
  .profile-image {
    position: relative;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    margin: 10px auto;

    .avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    .sb-avatar {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10%;
    }
    .icon {
      position: absolute;
      left: 60%;
      bottom: 10%;
    }
  }
  .contact {
    color: ${COLORS.lightGray1};
  }
  .drop-namecard {
    display: flex;
    justify-content: center;
    a,
    button {
      background: ${COLORS.primaryLight};
      color: #FFF;
      width: 100%;
      max-width: 150px;
      border-radius: 28px;
      padding: 0.5em 0.8em;
      box-shadow: none;
      outline: none;
      border: solid 1px ${COLORS.primary};
      margin-bottom: 1em;
      font-size: 1em;
      font-weight: 500;
      transition: 100ms;
      display: flex;
      align-items: center;
      svg {
        width: 40px;
      }
    }
  }
`

export default function ContactCard({ user, isSelf, currentUser, setViewUser }) {
  const [modal, setModal] = useState(false)

  const openModal = () => setModal(true)
  const hideModal = () => setModal(false)

  const handleCloseContact = () => {
    setViewUser(null)
  }
  return (
    <Page>
      <DropNamecardModal currentUser={currentUser} hideModal={hideModal} modal={modal} user={user} />
      <div className="contact-card-header">
        <h5 className="mb-4 heading">Contact Card</h5>
        <p className="contact-close" onClick={handleCloseContact}>Close</p>
      </div>
      <div className="profile-image">
        {
          user.avatar ?
          <img src={user.avatar.url} width="125px" className="avatar" alt="user-profile" />
          :
          <Avatar round name={user.fullName} size="125px" />
        }
        {/* <svg className="icon" height="30" width="30">
          <g transform="translate(0 -1028.4)">
            <path d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z" transform="translate(0 1029.4)" fill="#fff"/>
            <path d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z" transform="translate(0 1028.4)" fill="#C3A97A"/>
            <path d="m6.0001 1042.4h4.9999v5h2v-5h5v-2h-5v-5h-2v5h-4.9999v2z" fill="#fff"/>
            <path d="m6 1041.4h5v5h2v-5h5v-2h-5v-5h-2v5h-5v2z" fill="#fff"/>
          </g>
        </svg> */}
      </div>
      <div className="details">
        <h5 className="text-center">{user.fullName}</h5>
        <p>{user.companyName}</p>
        <p className="contact">{user.designation}</p>
        {/* <p className="contact">
          Mobile: {user.phone}
          <br />
          Email: {user.email}
        </p> */}
        {isSelf ? (
          <p className="text-dark">
            <em>This is your information</em>
          </p>
        ) : (
          <div className="drop-namecard">
            <button onClick={openModal}>
              <svg width="28.393" height="23.935" viewBox="0 0 28.393 23.935">
                <path d="M28.256,18.7a14.586,14.586,0,0,1-2.475-3.636c-.143-.382-.321-.921-.526-1.538-.98-2.954-1.871-5.466-2.859-6.3-1.071-.9-5.208-1.5-6.909-1.719V2.176A2.407,2.407,0,0,0,12.906,0H2.581A2.407,2.407,0,0,0,0,2.176V17.407a2.407,2.407,0,0,0,2.581,2.176H12.906a2.9,2.9,0,0,0,.811-.129,11.365,11.365,0,0,0,1.866,1.115,9.152,9.152,0,0,1,3.863,3.095.675.675,0,0,0,.558.271,9.1,9.1,0,0,0,8.346-4.7A.476.476,0,0,0,28.256,18.7ZM15.487,10.051l2.045.69c.1.3.27.773.44,1.146a4.082,4.082,0,0,0-.217.385,2.946,2.946,0,0,1-.424.662,6.49,6.49,0,0,1-1.843-2.085ZM2.581,18.5a1.2,1.2,0,0,1-1.291-1.088V2.176A1.2,1.2,0,0,1,2.581,1.088H12.906A1.2,1.2,0,0,1,14.2,2.176V8.7A6.631,6.631,0,0,0,10.8,6.313a1.868,1.868,0,0,0-1.579.374c-1.554,1.31-.664,3.536.57,5.045a8.133,8.133,0,0,1,1.825,3.551c.017.152.025.3.033.439a4.177,4.177,0,0,0,1.12,2.773Zm17.783,4.345a10.632,10.632,0,0,0-4.112-3.2,6.885,6.885,0,0,1-2.179-1.464,3.448,3.448,0,0,1-1.132-2.5c-.009-.16-.018-.322-.038-.492a9.009,9.009,0,0,0-2.047-4.065c-1-1.216-1.644-2.879-.72-3.659a.445.445,0,0,1,.409-.078A6.09,6.09,0,0,1,13.6,10c.494,1.031,1.831,3.5,3.577,4.107a.756.756,0,0,0,.606-.05,2.949,2.949,0,0,0,1.155-1.343,3.224,3.224,0,0,0,.145-.294.5.5,0,0,0,.21-.691,10.8,10.8,0,0,1-.6-1.53.588.588,0,0,0-.384-.364l-2.821-.951V6.614c2.345.306,5.389.874,6,1.386.829.7,1.893,3.906,2.529,5.822.209.631.392,1.182.539,1.572A14.311,14.311,0,0,0,27,19.109,7.815,7.815,0,0,1,20.365,22.841Z" fill="#fff"/>
                <path d="M85.978,42.667a.645.645,0,0,0-.645.645V54.928a.645.645,0,0,0,1.291,0V43.312A.645.645,0,0,0,85.978,42.667Z" transform="translate(-80.171 -40.086)" fill="#fff"/>
                <path d="M43.311,42.667a.645.645,0,0,0-.645.645v6.453a.645.645,0,1,0,1.291,0V43.312A.645.645,0,0,0,43.311,42.667Z" transform="translate(-40.085 -40.086)" fill="#fff"/>
              </svg>

              {/* <img src={bizCard} width="30px" alt="business card"/> */}
              <span>Drop Your Name Card</span>
            </button>
          </div>
        )}
        {/* <p className="font-weight-bold font-italic">ShoutOut Message</p> */}
        {/* <p><small className="text-secondary font-italic">Marketer by day, gamer at night. </small></p> */}
        {/* <Button variant="default" className="my-3" >Edit Profile</Button> */}
      </div>
    </Page>
  )
}
