import LogoL from './images/logo.png'
import Entrance from './images/backdrops/entrance.jpg'
import EntranceM from './images/backdrops/entranceP.jpg'
import LobbyL from './images/backdrops/lobby.jpg'
import Exhibition from './images/backdrops/exhibition-hall.jpg'
import PostregisterL from './images/backdrops/postregisterL.jpg'
import PostregisterP from './images/backdrops/postregisterP.jpg'
import CountdownL from './images/backdrops/countdownL.jpg'
import CountdownP from './images/backdrops/countdownP.jpg'
import RegisterL from './images/backdrops/registerL.jpg'
import RegisterP from './images/backdrops/registerP.jpg'
import Auditorium from './images/backdrops/auditorium.jpg'
import AuditoriumM from './images/backdrops/auditoriumP.jpg'
import MC from './images/backdrops/MC20.png'
import NetworkingL from './images/backdrops/networking.jpg'
import NetworkingP from './images/backdrops/networkingP.jpg'
import LobbyMusic from './images/audio/lobby.mp3'
import routes from './routes'
import Gallerium from './images/backdrops/gallerium.jpg'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://dkshmedflix2021.herokuapp.com',
}

const API_KEYS = {
  amplitude: '2fabd85655f457b015b2dc62587539b0',
}
const eventDays = [19,20,21]

const config = process.env.NODE_ENV === 'production' ? prod : dev

const assets = {
  logo: {
    landscape: LogoL,
    portrait: LogoL,
  },
  countdown: {
    landscape: CountdownL,
    portrait: CountdownP,
  },
  postregister: {
    landscape: PostregisterL,
    portrait: PostregisterP,
  },
  register: {
    landscape: RegisterL,
    portrait: RegisterP,
  },
  entrance: {
    landscape: Entrance,
    portrait: EntranceM,
  },
  lobby: {
    landscape: LobbyL,
    portrait: EntranceM,
  },
  auditorium1: {
    landscape: Auditorium,
    portrait: AuditoriumM,
  },
  auditorium2: MC,

  exhibition1: Exhibition,
  exhibition2: Exhibition,

  networking: {
    landscape: NetworkingL,
    portrait: NetworkingP,
  },
  gallerium: Gallerium,

  lobbyMusic: LobbyMusic,
}

const layout = {
  auditoriumVid: `
    top: 14%;
    bottom: 23%;
    left: 2%;
    right: 34.0%;
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    destination: routes.auditorium1,
    css: `
      top: 66.7%;
      left: 32.5%;
      right: 63.6%;
      bottom: 35.7%;
    `,
  },
  {
    id: 'exhibition',
    destination: routes.exhibition1,
    css: `
      top: 66.7%;
      left: 42%;
      right: 54.5%;
      bottom: 35.7%;
    `,
  },
  {
    id: 'networking',
    destination: routes.networking,
    css: `
      top: 66.7%;
      left: 52.0%;
      right: 44.6%;
      bottom: 35.7%;
    `,
  },
  {
    id: 'gallerium',
    destination: routes.gallerium,
    css: `
      top: 66.7%;
      left: 61.5%;
      right: 35.2%;
      bottom: 35.7%;
    `,
  },
  {
    id: 'helpdesk',
    destination: 0,
    css: `
      top: 90%;
      left: 82.2%;
      right: 14.8%;
      bottom: 15.5%;
    `,
  },
]

export default {
  API_KEYS,
  assets,
  layout,
  lobbyEntries,
  eventDays,

  eventName: 'DKSH MEDflix 2021',

  ...config,
}
