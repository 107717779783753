import React, { useState } from 'react'
import styled from 'styled-components'
import replay from '../images/icons/replay.png'
import block from '../images/icons/replay-NA.png'
import { toast } from 'react-toastify'
import Axios from 'axios'
import config from '../config'
import { SIZES } from '../styles/theme'

const StyledAgenda = styled.div`
  .agenda-item {
    padding: 4px 0;
    .end {
      color: #ffad2c;
    }
    .attend, .replay {
      border: none;
      background: transparent;
      height: 40px;
      width: 52px;
      margin: 0;
      padding: 0;
      p.load {
        margin: 0;
        font-weight: 500;
        color: red;
        font-size: 8px;
      }
      img {
        height: 100%;
      }
      img.pending, svg.pending {
        transform: scale(0.9);
      }
      .icon-label {
        color: white;
        text-align: center;
        font-size: 6.5px;
        opacity: 1;
      }
      .checked {
        margin-top: 0px ;
      }
      &:hover {
        img.pending, svg.pending {
          transform: scale(1);
        }
        .icon-label {
          font-size: 1px;
          opacity: 0;
        }
      }
    }
  }
  
  .time-container {
    width: 18%;
  }
  .desc-container {
    display: block;
    width: 60%;
  }
  .buttons-container {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  ${SIZES.mobile} {
    .time-container {
      margin-top: 2px;
      font-size: 14px;
    }
    .desc-container {
      width: 60%;
    }
  }
`

export default function KfairAgendaItem({
  handleVideo,
  agenda,
  agendaList,
  setAgendaList,
  currentUser,
  requestLoading,
  setRequestLoading,
}) {
  const [isLoading, setIsLoading] = useState(false)

  const takeAttendance = (id) => {
    if (agendaList.filter((item) => item.id === id)[0].attendance === true)
      return

    setIsLoading(true)
    setRequestLoading(true)

    Axios({
      method: 'post',
      url: `${config.apiUrl}/api/v1/hall_sessions/${id}/check_in`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    })
      .then((response) => {
        setIsLoading(false)
        setRequestLoading(false)
        if (response.data.success) {
          toast(`Check-in successful.`)
          const newAgendaList = agendaList.map((item, index) => ({
            ...item,
            attendance: item.id === id ? true : item.attendance,
          }))
          setAgendaList(newAgendaList)
        } else {
          toast(response?.data?.error || 'Check-in failed. Something went wrong, please try again.')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setRequestLoading(false)
        if (err?.response?.data?.error) {
          toast(err?.response?.data?.error)
        } else {
          toast('Check-in failed. Something went wrong')
        }
      })
  }
  const replayVideo = (url) => {
    handleVideo(url)
  }

  return (
    <StyledAgenda>
      <div className="d-flex justify-content-between agenda-item my-4">
        <div className="time-container">
          <small className="d-block start">{agenda.start}</small>
          <small className="d-block end">{agenda.end}</small>
        </div>
        <small
          className="desc-container"
          dangerouslySetInnerHTML={{ __html: agenda.desc }}
        />
        <div className="buttons-container">
          <button
            className="attend"
            onClick={() => takeAttendance(agenda.id)}
            disabled={isLoading || agenda.attendance || requestLoading}
          >
            {isLoading ? (
              <p className="load">Loading</p>
            ) : (
              <>
                {
                  agenda.attendance ?
                    <svg className="checked" width="28" height="30.086" viewBox="0 0 28 30.086">
                      <text transform="translate(0 28.086)" fill="#ffad2c" fontSize="6" fontFamily="Montserrat-SemiBold, Montserrat" fontWeight="600"><tspan x="0" y="0">Checked</tspan></text>
                      <path d="M99.86-81.8a2.783,2.783,0,0,1-2.027-.871l-6.685-7.034a2.8,2.8,0,0,1,.1-3.951,2.794,2.794,0,0,1,3.952.1l4.815,5.067,13.7-12.259a2.8,2.8,0,0,1,3.947.22,2.8,2.8,0,0,1-.22,3.949L101.723-82.514A2.784,2.784,0,0,1,99.86-81.8Z" transform="translate(-90.38 101.459)" fill="#ffad2c" />
                    </svg>
                    :
                    <svg className="pending" width="19.458" height="28.095" viewBox="0 0 19.458 28.095">
                      <g transform="translate(-8.386)">
                        <g transform="translate(8.386)">
                          <path d="M101.008-112.281a9.728,9.728,0,0,0-9.73,9.728c0,4.664,3.294,12.426,7.669,17.4a2.828,2.828,0,0,0,4.312-.067c3.567-4.31,7.477-12.929,7.477-17.335A9.728,9.728,0,0,0,101.008-112.281Zm0,13.919a3.891,3.891,0,0,1-3.892-3.892,3.891,3.891,0,0,1,3.892-3.892,3.891,3.891,0,0,1,3.89,3.892A3.891,3.891,0,0,1,101.008-98.363Z" transform="translate(-91.278 112.281)" fill="#fff" />
                        </g>
                      </g>
                    </svg>
                }
                <small className={`icon-label ${agenda.attendance ? `d-none` : `d-block`}`}>Check-in</small>
              </>
            )}
          </button>
          <button className="replay" onClick={() => replayVideo(agenda)}>
            <img
              src={agenda.url ? replay : block}
              className={agenda.url ? `pending` : `checked`}
              alt="replay-button"
              style={{height:'80%'}}
            />
            <small
              className={`icon-label ${agenda.url ? `d-block` : `d-none`}`}
            >
              Replay
            </small>
          </button>
        </div>
      </div>
    </StyledAgenda>
  )
}
