import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import config from '../config'
import routes from '../routes'
import { SIZES } from '../styles/theme'
import { Link } from 'react-router-dom'
import banner from '../images/backdrops/networking-logo.png'
import logo from '../images/backdrops/gallery-logo.png'
import PageWithBg from '../components/PageWithBg'
import backIcon from '../images/icons/back.png'
import playIcon from '../images/icons/play_circle.png'
import Amplitude from '../utils/amplitudeHelper'
import materials from '../constants/galleryList'
import ModalVideo from 'react-modal-video'
import { usePageActiveUserCount } from '../utils/activeUserCount'

const Page = styled.div`
  .full {
    position: absolute;
    padding-top: ${SIZES.navHeight};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;

    .sidenavs {
      height: 55vh;
      width: 25%;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: space-between;
      color: white;
    }
    .main {
      width: 75%;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 32px 56px;
      padding-right: 72px;
      height: 100%;
      overflow-y: scroll;
      .listing {
        margin-top: 36px;

        .card-thumbnail {
          background: white;
          width: 175px;
          height: 175px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          margin-bottom: 32px;
          cursor: pointer;
          position: relative;
          &:hover {
            .play-icon {
              transition: 200ms;
              transform: scale(1.2);
            }
          }
          .play-icon {
            position: absolute;
          }
        }
      }
      .toggle-back {
        font-weight: bold;
        color: #171717;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        text-decoration: none;
        .back-prev {
          display: flex;
          width: 30px;
          flex-direction: column;
          transition: 1s;
          img {
            width: 26px;
          }
          .icon-label {
            text-align: left;
            font-size: 1px;
            opacity: 0;
          }
          &:hover {
            img {
              transform: scale(0.6);
            }
            .icon-label {
              font-size: 8px;
              opacity: 1;
            }
          }
        }

        span {
          font-size: 24px;
          font-style: italic;
          margin-left: 1em;
        }
      }
    }
  }
`

export default function PosterGalleryPage({ currentUser }) {
  const [video, setVideo] = useState(null)
  const [videoOpen, setVideoOpen] = useState(false)

  usePageActiveUserCount(currentUser)
  
  useEffect(() => {
    document.title = `${config.eventName} | Gallery`
  }, [])

  useEffect(() => {
    Amplitude.visit(`Gallery`)
  }, [currentUser.id])

  const showVideo = (item) => {
    if (item.videoId === "pdf") {
      window.open(
        item.link,
        "_blank"
      )
    } else {
      setVideo(item)
      setVideoOpen(true)
    }
    Amplitude.viewGallery(item.id)
  }
 
  return (
    <Page>
      <PageWithBg bgImg={config.assets.postregister.landscape}>
        <section className="full">
          <div className="sidenavs">
            <img
              src={banner}
              className=""
              width="100%"
              alt="banner"
            />
            
            <img
              src={logo}
              className="d-block"
              width="95%"
              alt="event logo"
            />
          </div>
          <div className="main">
            <Link to={routes.lobby} className="toggle-back">
              <div className="back-prev">
                <img src={backIcon} alt="back-icon" />
                <small className="icon-label">Back</small>
              </div>
              <span>Gallery</span>
            </Link>
            <div className="d-flex flex-wrap listing">
              {
                materials.map((video) => (
                  <div
                    className="card-thumbnail"
                    onClick={() => showVideo(video)}
                  >
                    <img src={video.image} width="100%" alt="gallery thumbnail" />
                    { video.videoId !== 'pdf' && <img className='play-icon' src={playIcon} width="25%"  alt="play icon"/>}
                  </div>
                ))
              }
            </div>
            {video && (
              <ModalVideo
                channel="vimeo"
                autoplay
                isOpen={videoOpen}
                videoId={video.videoId}
                onClose={() => setVideoOpen(false)}
              />
            )}
          </div>
        </section>
      </PageWithBg>
    </Page>
  )
}
