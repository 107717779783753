import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import config from '../config'
import { COLORS, SIZES } from '../styles/theme'
import bannerP from '../images/backdrops/banner.png'
import bannerL from '../images/backdrops/event-banner.png'
import { Link } from 'react-router-dom'
import routes from '../routes'
import EntrancePages from '../containers/EntrancePages'

const Page = styled.div`
  min-height: 100vh;
  width: 100vw;
  text-align: center;

  .mobile-hide {
    display: inline-block;
    margin: 0 6px;
  }
  ${SIZES.mobile} {
    .mobile-hide {
      display: none;
    }
    .mobile-class {
      font-weight: 400;
      display: block;
    }
  }
  #content {
    position: absolute;
    top: 0;
    width:100%;
  }

  #header {
    padding-top: 3vh;
    background: linear-gradient(#CE2440, #CE2440, #800B1E);

    h1 {
      color: #fff;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 0.8em;
      font-size: 2rem;
    }

    .time-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-transform: uppercase;

      .time-card {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(242, 241, 241, 1) 70%,
          rgba(180, 179, 179, 1) 100%
        );

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        min-width: 120px;
        width: 10vw;
        margin: 0 1%;
        margin-top: 5px;
        padding-top: 10px;

        p {
          margin-bottom: 6px;
          font-weight: 500;
        }

        h2 {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
  }

  #bg {
    background: url(${config.assets.countdown.landscape});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    min-height: 100%;
    width: 100%;
    /* height: calc(100vh - 200px); */
    padding-top: 2.5rem;
    height: 100vh;
    img#portrait {
      display: none;
    }
    .mobile-show {
      display: none;
    }
    ${SIZES.mobile} {
      /* background: url(${config.assets.countdown.landscape}); */
      background: linear-gradient(45deg, #1b083f, #1a083f, #1a083f, #413273);
      background-size: cover;
      background-position: center top;
      padding-top: 48px;

      img#portrait {
        display: block;
        margin: 0 auto;
      }
      .mobile-show {
        display: block;
      }
    }
  }
  #register-btn {
    min-width: 225px;
    width: 25%;
    position: absolute;
    border: 2px solid #fff;
    font-style: italic ;
    left: 50%;
    margin-top: -16px;
    transform: translateX(-50%);
    background: #CE2440;
    color: #FFFFFF;
    font-weight: 500;
    padding: 4px 28px;
    border-radius: 32px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    div {
      line-height: 30px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.4em;
      align-self: flex-end;
    }
  }
  .back-top {
    border: none;
    border-radius: 12px;
    background: #CE2440;
    position: fixed;
    bottom: 5%;
    left: 5%;
    svg {
      width:50;
      height:50;
    }
  }

`

export default function CountdownPage() {
  const calculateTimeLeft = () => {
    // year, actual month - 1, day, hour, minute, second, ignore
    // 19 Mar 2021 8am
    const difference = +new Date(2021, 2, 19, 8, 0, 0, 0) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (isNaN(timeLeft[interval])) {
      return
    }

    timerComponents.push(
      <div className="time-card">
        <p>{interval}</p>
        <h2>{timeLeft[interval]}</h2>
      </div>
    )
  })
  const topRef = useRef()

  const scrollTop = (ref) =>{
    ref.current.scrollIntoView({ behavior: 'smooth' })
  };

  if (!timerComponents.length) {
    return <EntrancePages />
  }
  return (
    <Page>
      <div id="content">
        <div id="header">
          <span ref={topRef}></span>
          <h1>MEDflix 2021<span className="mobile-hide">-</span><span className="mobile-class">Multidisciplinary Medical Conference</span></h1>
          <div className="time-wrapper">
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
          </div>
        </div>
        <div id="bg">
          <Link to={routes.register} id="register-btn">
            <div>REGISTER HERE</div>
            <div className="arrow">⇀</div>
          </Link>
          {/* <img id="landscape" src={bannerL} width="60%" alt="banner"/> */}
          <img id="portrait" src={config.assets.countdown.portrait} width="100%" alt="banner"/>
          <button onClick={()=>scrollTop(topRef)} className="back-top mobile-show">
            <svg width="45" height="45" viewBox="0 0 45 45">
              <g transform="translate(-1767 -3747)">
                <g transform="translate(120 -2428)">
                  <path d="M22.175,9.358a1.43,1.43,0,0,0-1.43-1.43H5.063l5.813-5.42A1.43,1.43,0,1,0,8.923.421L.455,8.311a1.43,1.43,0,0,0,0,2.091L8.923,18.3A1.43,1.43,0,1,0,10.875,16.2L5.063,10.788H20.746A1.43,1.43,0,0,0,22.175,9.358Z" transform="translate(1678.681 6187) rotate(90)" fill="#fff"/>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </Page>
  )
}
