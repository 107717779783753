import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';


import routes from '../../routes';
import config from '../../config';

import MobileLanding from './MobileLanding';
import MobileLogin from './MobileLogin';
import MobileForgotPassword from './MobileForgotPassword';
import MobileResetPassword from './MobileResetPassword';
import logo from "../../images/backdrops/Logo-Event.png"

const MobileEntrance = () => {
  return (
    <Page>
      <div id="content">
        <div></div>
        {/* <img id="logo" src={logo} alt="logo" style={{zIndex: 10}} /> */}
        
        <Switch>
          <Route exact path={routes.root}>
            <MobileLanding />
          </Route>

          <Route exact path={routes.login}>
            <MobileLogin />
          </Route>

          <Route exact path={routes.forgotPassword}>
            <MobileForgotPassword />
          </Route>

          <Route exact path={routes.resetPassword}>
            <MobileResetPassword />
          </Route>
        </Switch>
      </div>
    </Page>
  );
}

const Page = styled.div`
  background: url(${config.assets.entrance.portrait});
  background-size: cover;
  background-position: top;
  height: 100vh;
  width: 100vw;

  #logo {
    width: 90%;
    top: 13%;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
  }
  #content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  @media screen and (min-width: 540px) {
    #logo {
      width: 80%;
      top: 15%;
    }
  }
`

export default MobileEntrance;