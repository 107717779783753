import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import whatsapp from '../../images/booth/whatsapp.png'

const StyledModal = styled(Modal)`
  .modal-content {
    border: solid 2px #5c628a;
    border-radius: 28px;
  }
  .modal-header {
    border: none;
    padding-right: 32px;
    padding-bottom: 0;
  }
  .modal-body {
    padding: 16px 32px;
    .content {
      width: 100%;
      overflow-x: scroll;
      padding-bottom: 16px;
    }
    .divider {
      background: linear-gradient(270deg, #FE1E9A, #254DDE);
      width: 100%;
      height: 4.5px;
      margin: 16px auto;
    }
    .contact {
      width: 100%;
      margin: 16px 0;
      .contact-name {
        color: #8669b1;
      }
    }
  }
`

export default function InfoModal({ title, description, content, infoOpen, setInfoOpen, agents }) {
  return (
    <StyledModal
      show={infoOpen}
      onHide={()=>setInfoOpen(false)}
      size="lg"
      aria-labelledby="info-modal"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="info-modal" className="px-3">{title}</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="contact">
          <div >
            <h5 className="p-0">
              Contact Informations
              <img src={whatsapp} width="24px" className="ml-3" alt="whatsapp logo" />
            </h5>
          </div>
          {
            agents.map((agent,index)=>(
              <div key={`Agent-${index+1}`} >
                <p className="contact-name my-0 font-weight-bold">{agent.name}</p>
                <p className="mb-0">
                  <a href={`https://wa.me/${agent.phone}`} target="_blank" rel="noopener noreferrer">
                    {agent.phone}
                  </a>
                </p>
                <p>
                  <a href={`mailto:${agent.email}`} target="_blank" rel="noopener noreferrer">
                    {agent.email}
                  </a>
                </p>
              </div>
            ))
          }
        </div>
      </Modal.Body>
    </StyledModal>
  )
}
