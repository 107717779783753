import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import { useParams, useHistory } from 'react-router-dom'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'
import { loadTawk } from '../utils/tawkToHelper'
import { getBooth, getBooths } from '../ducks/booth'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import DownloadModal from '../components/GlobalModals/DownloadModal'
import IframeModal from '../components/GlobalModals/IframeModal'
import PosterModal from '../components/GlobalModals/PosterModal'
import InfoModal from '../components/GlobalModals/InfoModal'
import VideoModal from '../components/GlobalModals/VideoModal'
import TawkModal from '../components/GlobalModals/TawkModal'
import photoIcon from '../images/booth/photo.png'
import pdfIcon from '../images/booth/pdficon.png'
import infoIcon from '../images/booth/info.png'
import boothBg from '../images/backdrops/boothBg.jpg'
import BoothNamecardModal from '../components/GlobalModals/BoothNamecardModal'
import { usePageActiveUserCount } from '../utils/activeUserCount'

const Page = styled.div`
  overflow: hidden;
  #button-container {
    position: absolute;
    bottom: 4vh;
    left: 2%;
    .hot-buttons {
      width: 125px;
    }
  }
  .hot-buttons,
  .hot-actions {
    background: #CE2440;
    border-radius: 40px;
    border: 1px solid #fff;
    padding: 8px 16px;
    color: #fff;
    margin: 0 8px;
    &:hover {
      box-shadow: 0px 5px 4px -4px #000;
    }
    small {
      margin: 0;
      display: block;
      font-weight: 500;
      font-size: 70%;
    }
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .booth-container {
    position: fixed;
    left: 50%;
    width: 75vw;
    max-width: 1350px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .hot-actions {
      border-radius: 50%;
      padding: 0;
      img,
      svg {
        transition: 1000ms;
        width: 28px;
        height: 28px;
      }
      small {
        transition: 1000ms;
        /* font-size: 1px; */
        /* opacity: 0; */
        /* display: none; */
        color: white;
        font-size: 8px;
        opacity: 1;
        display: block;
      }
      /* &:hover {
        box-shadow: none;
        small {
          font-size: 8px;
          opacity: 1;
          display: block;
        }
        small.extra-small {
          font-size: 6px;
        }
        img,
        svg {
          width: 20px;
          height: 20px;
        }
      } */
    }
  }
  #tawk-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 40vw;
    height: 40vh;
    z-index: 9999;
  }
  .exhibitor-title {
    text-align: right;
    width: 40vw;
    position: fixed;
    bottom: 3%;
    right: 5%;
    font-weight: 600;
  }
`
const HotButton = styled.button`
  position: absolute;
  width: 60px;
  height: 60px;
  ${(props) => props.css}
`

const EMBED_DIV_ID = 'TAWK_EMBED'

export default function BoothPage({ currentUser }) {
  const [showBtn, setShowBtn] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [posterOpen, setPosterOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [tawkOpen, setTawkOpen] = useState(false)
  const [nameCardModal, setNameCardModal] = useState(false)
  const [playVideo, setPlayVideo] = useState({})

  const dispatch = useDispatch()
  const { viewBooth, booths } = useSelector((state) => state.booth)
  const { boothId } = useParams()
  const history = useHistory()

  usePageActiveUserCount(currentUser)
  
  useEffect(() => {
    dispatch(getBooth(boothId))
  }, [boothId, dispatch])

  useEffect(() => {
    if (viewBooth && viewBooth.id == boothId) {
      dispatch(getBooths(viewBooth.hall.id))
      Amplitude.visitBooth(viewBooth.title)
    }
  }, [boothId, currentUser.id, viewBooth])

  const handleModal = (video) => {
    setPlayVideo({
      title: 'Video',
      link: video + '?autoplay=1',
    })
    setOpen(true)
    setVideoOpen(false)
  }

  const handleDownload = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'pdf')
    setDownloadOpen(!downloadOpen)
  }

  const handleVideoModal = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'video')
    if (viewBooth.video_urls.length > 1) {
      setVideoOpen(!videoOpen)
    } else {
      setPlayVideo({
        title: 'Video',
        link: viewBooth.video_urls[0],
      })
      setOpen(true)
      setVideoOpen(false)
    }
  }
  const handleDropNameCard = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'namecard')
    setNameCardModal(!nameCardModal)
  }
  const handlePoster = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'poster')
    setPosterOpen(!posterOpen)
  }
  const handleInfo = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'info')
    setInfoOpen(!infoOpen)
  }

  const openTawk = () => {
    if (viewBooth) {
      Amplitude.clickHotButtons(viewBooth.title, 'chat')
      const { chat_link } = viewBooth
      loadTawk(currentUser, chat_link, EMBED_DIV_ID)
      setTawkOpen(!tawkOpen)
    }
  }

  const navigatePrev = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
    history.push(`/booths/${navigateTo}`)
  }
  const navigateNext = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
    history.push(`/booths/${navigateTo}`)
  }

  return (
    <Page>
      <PageWithBg bgImg={boothBg}>
        {!showBtn && <LoadIcon />}
        {viewBooth && (
          <LazyLoadComponent>
            <div className="booth-container">
              <LazyLoadImage
                effect="black-and-white"
                afterLoad={() => setShowBtn(true)}
                className="booth-img"
                src={viewBooth.background_image.url}
                width="100%"
                alt="booth image"
              />
              {showBtn && (
                <>
                  {
                    viewBooth.video_urls.length !== 0 &&
                    <HotButton
                      css={viewBooth.btnPos.vid}
                      className="hot-actions"
                      onClick={handleVideoModal}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <polygon points="10 8 16 12 10 16 10 8"></polygon>
                      </svg>
                      <small>
                        Video
                        <br />
                        Gallery
                      </small>
                    </HotButton>
                  }
                  <HotButton
                    css={viewBooth.btnPos.pdf}
                    className="hot-actions"
                    onClick={handleDownload}
                  >
                    <img src={pdfIcon} width="26" alt="Nav" />
                    <small>
                      PDF
                      <br />
                      Gallery
                    </small>
                  </HotButton>
                  <HotButton
                    css={viewBooth.btnPos.image}
                    className="hot-actions"
                    onClick={handlePoster}
                  >
                    <img src={photoIcon} width="26" alt="Nav" />
                    <small>
                      Image
                      <br />
                      Gallery
                    </small>
                  </HotButton>
                  <HotButton
                    css={viewBooth.btnPos.info}
                    className="hot-actions"
                    onClick={handleInfo}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        fill="#fff"
                        d="M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M19,16 L6,16 L4,18 L4,5 C4,4.45 4.45,4 5,4 L19,4 C19.55,4 20,4.45 20,5 L20,15 C20,15.55 19.55,16 19,16 Z"
                      ></path>
                    </svg>
                    <small>
                      Talk
                      <br />
                      To Us
                    </small>
                  </HotButton>
                  {
                    viewBooth.email && (
                      <HotButton
                        css={viewBooth.btnPos.namecard}
                        className="hot-actions"
                        onClick={handleDropNameCard}
                      >
                        <svg width="28.393" height="23.935" viewBox="0 0 28.393 23.935">
                          <path d="M28.256,18.7a14.586,14.586,0,0,1-2.475-3.636c-.143-.382-.321-.921-.526-1.538-.98-2.954-1.871-5.466-2.859-6.3-1.071-.9-5.208-1.5-6.909-1.719V2.176A2.407,2.407,0,0,0,12.906,0H2.581A2.407,2.407,0,0,0,0,2.176V17.407a2.407,2.407,0,0,0,2.581,2.176H12.906a2.9,2.9,0,0,0,.811-.129,11.365,11.365,0,0,0,1.866,1.115,9.152,9.152,0,0,1,3.863,3.095.675.675,0,0,0,.558.271,9.1,9.1,0,0,0,8.346-4.7A.476.476,0,0,0,28.256,18.7ZM15.487,10.051l2.045.69c.1.3.27.773.44,1.146a4.082,4.082,0,0,0-.217.385,2.946,2.946,0,0,1-.424.662,6.49,6.49,0,0,1-1.843-2.085ZM2.581,18.5a1.2,1.2,0,0,1-1.291-1.088V2.176A1.2,1.2,0,0,1,2.581,1.088H12.906A1.2,1.2,0,0,1,14.2,2.176V8.7A6.631,6.631,0,0,0,10.8,6.313a1.868,1.868,0,0,0-1.579.374c-1.554,1.31-.664,3.536.57,5.045a8.133,8.133,0,0,1,1.825,3.551c.017.152.025.3.033.439a4.177,4.177,0,0,0,1.12,2.773Zm17.783,4.345a10.632,10.632,0,0,0-4.112-3.2,6.885,6.885,0,0,1-2.179-1.464,3.448,3.448,0,0,1-1.132-2.5c-.009-.16-.018-.322-.038-.492a9.009,9.009,0,0,0-2.047-4.065c-1-1.216-1.644-2.879-.72-3.659a.445.445,0,0,1,.409-.078A6.09,6.09,0,0,1,13.6,10c.494,1.031,1.831,3.5,3.577,4.107a.756.756,0,0,0,.606-.05,2.949,2.949,0,0,0,1.155-1.343,3.224,3.224,0,0,0,.145-.294.5.5,0,0,0,.21-.691,10.8,10.8,0,0,1-.6-1.53.588.588,0,0,0-.384-.364l-2.821-.951V6.614c2.345.306,5.389.874,6,1.386.829.7,1.893,3.906,2.529,5.822.209.631.392,1.182.539,1.572A14.311,14.311,0,0,0,27,19.109,7.815,7.815,0,0,1,20.365,22.841Z" fill="#fff"/>
                          <path d="M85.978,42.667a.645.645,0,0,0-.645.645V54.928a.645.645,0,0,0,1.291,0V43.312A.645.645,0,0,0,85.978,42.667Z" transform="translate(-80.171 -40.086)" fill="#fff"/>
                          <path d="M43.311,42.667a.645.645,0,0,0-.645.645v6.453a.645.645,0,1,0,1.291,0V43.312A.645.645,0,0,0,43.311,42.667Z" transform="translate(-40.085 -40.086)" fill="#fff"/>
                        </svg>
                        <small className="extra-small">
                          Drop
                          <br />
                          Namecard
                        </small>
                      </HotButton>
                    )
                  }
                </>
              )}
            </div>
            <div id="button-container">
              <button
                className="hot-buttons"
                onClick={() =>
                  history.push(`/exhibition-hall-${viewBooth.hall.id}`)
                }
              >
                <svg width="53.514" height="21.333" viewBox="0 0 53.514 21.333">
                  <path d="M30,8a27.905,27.905,0,0,0-18.4,6.933L6.56,9.893A2.667,2.667,0,0,0,2,11.76V26.667a2.675,2.675,0,0,0,2.667,2.667H19.573a2.675,2.675,0,0,0,1.893-4.56L16.373,19.68A21.236,21.236,0,0,1,49.2,26.667a3.279,3.279,0,0,0,4,1.707,3.346,3.346,0,0,0,2-4.587A28.094,28.094,0,0,0,30,8Z" transform="translate(-2 -8)" fill="#fff"/>
                </svg>
                <small>Return to Hall</small>
              </button>
              {viewBooth.order !== 0 && (
                <button className="hot-buttons" onClick={navigatePrev}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <g transform="translate(17.301 26.839) rotate(180)">
                      <path d="M.459,14.462,13.147,26.407a1.637,1.637,0,0,0,2.216,0l1.48-1.393a1.415,1.415,0,0,0,0-2.084L6.79,13.419,16.845,3.909a1.415,1.415,0,0,0,0-2.084L15.363.432a1.637,1.637,0,0,0-2.216,0L.459,12.376A1.415,1.415,0,0,0,.459,14.462Z" transform="translate(17.301 26.839) rotate(180)" fill="#fff"/>
                    </g>
                  </svg>
                  <small>Previous Booth</small>
                </button>
              )}
              {viewBooth.order !== booths.length-1 && (
                <button className="hot-buttons" onClick={navigateNext}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <path d="M.459,12.376,13.147.432a1.637,1.637,0,0,1,2.216,0l1.48,1.393a1.415,1.415,0,0,1,0,2.084L6.79,13.419,16.845,22.93a1.415,1.415,0,0,1,0,2.084l-1.48,1.393a1.637,1.637,0,0,1-2.216,0L.459,14.462A1.415,1.415,0,0,1,.459,12.376Z" transform="translate(17.301 26.839) rotate(180)" fill="#fff"/>
                  </svg>
                  <small>Next Booth</small>
                </button>
              )}
            </div>
            <h4 className="exhibitor-title">{viewBooth.description}</h4>
            <VideoModal
              videos={viewBooth.video_urls}
              setVideoOpen={setVideoOpen}
              videoOpen={videoOpen}
              handleModal={handleModal}
            />
            <IframeModal
              iframeOpen={isOpen}
              setIframeOpen={setOpen}
              iframe={playVideo}
            />
            <DownloadModal
              files={viewBooth.documents}
              setDownloadOpen={setDownloadOpen}
              downloadOpen={downloadOpen}
            />
            <PosterModal
              images={viewBooth.images}
              posterOpen={posterOpen}
              setPosterOpen={setPosterOpen}
            />
            <InfoModal
              title={viewBooth.title}
              description={viewBooth.description}
              content={viewBooth.content}
              agents={viewBooth.booth_agents}
              infoOpen={infoOpen}
              setInfoOpen={setInfoOpen}
            />
            <TawkModal
              embedId={EMBED_DIV_ID}
              tawkOpen={tawkOpen}
              setTawkOpen={setTawkOpen}
            />
            <BoothNamecardModal 
              currentUser={currentUser} 
              hideModal={()=>setNameCardModal(false)} 
              modal={nameCardModal} 
              booth={viewBooth} 
            />
          </LazyLoadComponent>
        )}
      </PageWithBg>
    </Page>
  )
}
