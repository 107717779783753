import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import { useDispatch } from 'react-redux'
import { muteMusic, unmuteMusic } from '../ducks/layout'
import config from '../config'
import { SIZES } from '../styles/theme'
import ChatSection from '../components/ChatSection'
import banner from '../images/event/branding.png'
import { useHistory } from 'react-router-dom'
import routes from '../routes'
import SideAgenda from '../components/SideAgenda'
import { ChatKfairDb, AccessDb } from '../firebaseConfig'
import { toast } from 'react-toastify'
import { useObject } from 'react-firebase-hooks/database'
import { startTrack, endTrack } from '../utils/timerHelper'
import { usePageActiveUserCount } from '../utils/activeUserCount'

const Page = styled.div`
  overflow: hidden;
  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    background: black;
    z-index: 20;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .chat {
    max-width: 350px;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    top: 0;
    left: 67%;
    bottom: 0;
    right: 5%;
    z-index: 30;
  }
  .attendance {
    position: fixed;
    top: 5%;
    left: 3%;
    width: 18%;
    display: flex;
    animation: heartbeat 5s infinite;
    .checkin-icon {
      background: #CE2440;
      border-radius: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      letter-spacing: 2px;
      border: 2px solid #fff;
      padding: 8px 16px;
      width: 100%;
      small {
        font-weight: 700;
      }
    }
    .action-label {
      color: white;
      font-weight: 500;
      margin: 0 16px;
      font-style: italic;
      align-self: center;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0 auto;
      img {
        border-radius: 50%;
      }
    }
    &:hover {
      .action-label,
      img {
        transition: 500ms;
        transform: scale(1.2);
      }
      img {
        box-shadow: 0px 0px 30px 6px #ffad2c;
      }
    }
  }
  .actions {
    position: fixed;
    top: 72%;
    bottom: 23%;
    left: 27%;
    right: 42%;
    display: flex;
    justify-content: space-between;
    button {
      width: 150px;
      background-image: linear-gradient(#492777, #c4000c);
      border: 2px solid #e20613;
      border-radius: 16px;
      color: white;
      font-style: italic;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 1px;
      &:hover {
        box-shadow: 0px 10px 10px -10px #e20613;
        animation-name: bounce;
        animation-duration: 1s;
        animation-fill-mode: both;
      }
    }
  }
  #banner {
    width: 45%;
    position: fixed;
    bottom: 3.5%;
    left: 0;
    z-index: 10;
  }
`

export default function AuditoriumPage({
  liveUrl,
  hallBg,
  hallNumber,
  currentUser,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [sidebar, setSidebar] = useState(false)
  const [snapshot, loading, error] = useObject(AccessDb)

  const history = useHistory()
  const dispatch = useDispatch()

  usePageActiveUserCount(currentUser)

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Auditorium`
  }, [])

  useEffect(() => {
    if (!loading) {
      if (!snapshot.val() && currentUser.role !== 'superadmin') {
        toast('Auditorium Hall is not available now.', {
          toastId: 'eventToast',
        })
        history.push(routes.lobby)
      } else {
        Amplitude.visit('Auditorium')
      }
    }
  }, [loading, snapshot, currentUser.id])

  useEffect(() => {
    if (currentUser.id){
      startTrack(currentUser)
    }
    
    return () => {
      if (currentUser.id){
        endTrack(currentUser)
      }
    }
  }, [currentUser.id])

  const toggleSidebar = () => {
    if (sidebar === false) {
      Amplitude.clickCheckIn()
    }
    setSidebar(!sidebar)
  }

  if (loading || error) {
    return <LoadIcon />
  }
  return (
    <Page>
      <SideAgenda
        open={sidebar}
        toggleSidebar={toggleSidebar}
        hallNumber={hallNumber}
        currentUser={currentUser}
      />
      <PageWithBg bgImg={hallBg}>
        <div className="content">
          {isLoading && <LoadIcon />}
          <iframe
            title="Live Broadcast"
            src={liveUrl}
            allow="autoplay; fullscreen"
            allowFullScreen
            frameBorder="0"
            onLoad={() => {
              setIsLoading(false)
            }}
          />
        </div>
        <div className="chat h-100">
          <ChatSection
            currentUser={currentUser}
            firebaseDb={ChatKfairDb}
            hallName="MainAuditorium"
          />
        </div>
        <div className="attendance">
          <button className="checkin-icon" onClick={toggleSidebar}>
            <small>Session Playbacks</small>
            {/* <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M0,0H24V24H0Z" fill="none"/>
              <path d="M19,3H14.82A2.988,2.988,0,0,0,9.18,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM12,3a1,1,0,1,1-1,1A1,1,0,0,1,12,3ZM9.29,16.29,6.7,13.7a1,1,0,0,1,1.41-1.41L10,14.17l5.88-5.88A1,1,0,1,1,17.29,9.7L10.7,16.29a1,1,0,0,1-1.41,0Z" fill="#f8f4ff"/>
            </svg> */}
          </button>
        </div>
        <div id="banner">
          <img src={banner} width="100%" alt="event banner" />
        </div>
      </PageWithBg>
    </Page>
  )
}
