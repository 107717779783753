import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { COLORS } from '../styles/theme'
import Axios from 'axios'
import config from '../config'
import KfairAgendaItem from './KfairAgendaItem'
import ModalVideo from 'react-modal-video'
import LoadIcon from './LoadIcon'
import Amplitude from '../utils/amplitudeHelper'

const AgendaWrapper = styled.div`
  padding: 32px 0;
  header {
    .toggle-button {
      background: #CE2440;
      outline: none;
      border-radius: 32px;
      color: white;
      padding: 4px 12px;
      border: 1px solid white;
      margin-right: 32px;
      font-size: 18px;
      /* cursor: pointer; */
      &:hover:not(.active) {
        color: ${COLORS.primary};
        background: #ffad2c;
      }
    }
    .active {
      color: #fff;
      border: 2px solid #fff;
      font-style: italic;
      font-weight: bold;
    }
  }
  section.agenda-container {
    padding: 32px 0;
  }
`

export default function KfairAgenda({ currentUser }) {
  const [agendaList, setAgendaList] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [videoId, setVideoId] = useState('')
  const [dateTabIndex, setDateTabIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(false);

  const dateTab = [
    {
      name: '2021-03-20',
      value: '2021-03-20',
    },
    {
      name: '2021-03-21',
      value: '2021-03-21',
    },
  ]

  useEffect(() => {
    const videoList = {
      1: '526453584',
      2: '526459707',
      3: '526484666',
      4: '526535826',
      5: '526513391',
      6: '526767244',
      7: '526795581',
      8: '526802361',
      9: '526814836',
      10: '526838333',
      11: '526837964',
    }
    Axios({
      url: `${config.apiUrl}/api/v1/hall_sessions?hall_id=1`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    }).then((result) => {
      setIsLoading(false)
      const sortedSession = result.data.sessions.sort((a, b)=>{
        return new Date(a.start_at).getTime() - new Date(b.start_at).getTime(); 
      })
      const withVideo = sortedSession.map((item) => {
        return {
          ...item,
          url: videoList[item.id],
        }
      })
      setAgendaList(withVideo);
    })
  }, [])

  const handleVideo = (session) => {
    if (session.url !== '') {
      Amplitude.watchPlayback(session.id)
      setVideoId(session.url)
      setIsOpen(true)
    }
  }

  return (
    <AgendaWrapper>
      <header>
        {dateTab.map((item, index) => (
          <button
            key={`datetab-${index}`}
            className={`toggle-button ${
              index === dateTabIndex ? 'active' : ''
            }`}
            onClick={() => setDateTabIndex(index)}
          >
            {item.name}
          </button>
        ))}
      </header>
      <section className="agenda-container">
        {isLoading && <LoadIcon />}
        <div>
          {agendaList
            .filter((item) => item.date === dateTab[dateTabIndex].value)
            .map((agenda) => (
              <KfairAgendaItem
                key={agenda.id}
                handleVideo={handleVideo}
                agenda={agenda}
                agendaList={agendaList}
                setAgendaList={setAgendaList}
                setRequestLoading={setRequestLoading}
                requestLoading={requestLoading}
              />
            ))}
        </div>
      </section>
      <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setIsOpen(false)}
      />
    </AgendaWrapper>
  )
}
