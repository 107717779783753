import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import PageWithBg from '../components/PageWithBg'
import LobbyOverlay from '../components/LobbyOverlay'
import LobbyEntry from '../components/LobbyEntry'
// import center from '../images/backdrops/center.jpg'
import left from '../images/backdrops/left.jpg'
import right from '../images/backdrops/right.jpg'
import config from '../config'
import { usePageActiveUserCount } from '../utils/activeUserCount'
import { toast } from 'react-toastify'

const PageWrapper = styled.div`
  .trailer {
    position: absolute;
    top: 4.2%;
    left: 32.5%;
    right: 35.5%;
    bottom: 63.5%;
  }
  .left {
    position: absolute;
    top: 4.5%;
    left: 23.5%;
    right: 68.5%;
    bottom: 73.8%;
  }
  .right {
    position: absolute;
    top: 4.5%;
    left: 65.5%;
    right: 26.5%;
    bottom: 73.8%;
  }
`

export default function LobbyPage({ currentUser }) {
  const [overlayTitle, setOverlayTitle] = useState('Select hall to enter:')
  const [overlay, setOverlay] = useState(null)

  usePageActiveUserCount(currentUser)

  const showEntryOverlay = (destinations) => {
    setOverlay(destinations)
  }

  const hideOverlay = () => setOverlay(null)

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      hideOverlay()
    }
  }, [])

  const showHelpDesk = () => {
    toast("Event has ended.")
    // const cpt = window.chaport
    // cpt.on('ready', function () {
    //   cpt.setVisitorData({
    //     name: currentUser.fullName,
    //     email: currentUser.email,
    //   })
    //   cpt.open()
    // })
    // cpt.on('collapsed', function () {
    //   cpt.close()
    // })
    // Amplitude.clickHelpDesk()
  }

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
  }, [])

  useEffect(() => {
    Amplitude.visit("Lobby")
  }, [currentUser.id])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <LobbyOverlay
          overlayTitle={overlayTitle}
          overlay={overlay}
          hideOverlay={hideOverlay}
        />
        {config.lobbyEntries.map((entry) => {
          return (
            <LobbyEntry
              showHelpDesk={showHelpDesk}
              key={entry.id}
              setOverlayTitle={setOverlayTitle}
              showEntryOverlay={showEntryOverlay}
              {...entry}
            />
          )
        })}
        <div className="left">
          <img width="100%" src={left} alt="poster" />
        </div>
        <div className="right">
          <img width="100%" src={right} alt="poster" />
        </div>
        <div className="trailer">
          {/* <img width="100%" src={center} alt="poster" /> */}
          <iframe
            width="100%"
            height="100%"
            title="Event Trailer"
            src="https://vimeo.com/showcase/8252902/embed?autoplay=1&loop=1"
            allow="autoplay; fullscreen"
            allowFullScreen
            frameBorder="0"
          />
        </div>
      </PageWithBg>
    </PageWrapper>
  )
}
