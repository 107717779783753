import config from '../config'
import { firebaseDb } from '../firebaseConfig'
import { isMobile } from 'react-device-detect'

const trackDays = config.eventDays // only track time during eventDays

function startTrack(currentUser) {
  const today = new Date().getDate()
  if (currentUser.id && trackDays.includes(today)) {
    const timerRef =
      process.env.REACT_APP_STAGE === 'production'
        ? `timer/${today}/${currentUser.id}`
        : `timer/local/${today}/${currentUser.id}`
    let number_of_tabs = localStorage.getItem('open_tab') === null || isNaN(localStorage.getItem('open_tab')) ? 1 : parseInt(localStorage.getItem('open_tab'))+1
    localStorage.setItem("open_tab", number_of_tabs)

    firebaseDb.ref(timerRef).on("value", function(snapshot) {
      // firebaseDb.ref(timerRef + '/duration').set(!snapshot.val().duration ? 0 : snapshot.val().duration)
      return
    });
    if (number_of_tabs === 1 || isMobile) { // only when this tab is the only tab, then reset the time
      firebaseDb.ref(timerRef + '/userId').set(currentUser.id)
      firebaseDb.ref(timerRef + '/start' ).set(getCurrentTime())
    }
  }
}

function getCurrentTime() {
  return Date.now();
}

function calculateDiff(currentUser, today) {
  const end = getCurrentTime()
  const timerRef =
    process.env.REACT_APP_STAGE === 'production'
      ? `timer/${today}/${currentUser.id}`
      : `timer/local/${today}/${currentUser.id}`
  let diff;
  let duration;
  firebaseDb.ref(timerRef).on("value", function(snapshot) {
    diff = end - snapshot.val().start
    duration = (!snapshot.val().duration ? 0 : snapshot.val().duration) + diff/1000
    return
  });
  return duration
}

function endTrack(currentUser) {
  const today = new Date().getDate()
  if (currentUser.id && trackDays.includes(today)) {
    const timerRef =
      process.env.REACT_APP_STAGE === 'production'
        ? `timer/${today}/${currentUser.id}`
        : `timer/local/${today}/${currentUser.id}`
        
    const duration = calculateDiff(currentUser, today)
    if (!isNaN(duration)){
      firebaseDb.ref(timerRef + '/start').set(getCurrentTime())
      firebaseDb.ref(timerRef + '/duration').set(duration)
    }
    if (!isMobile) {
      let num_of_tabs = parseInt(localStorage.getItem("open_tab"))-1
      if (num_of_tabs < 1) {
        localStorage.removeItem("open_tab")
      } else {
        localStorage.setItem("open_tab", num_of_tabs )
      }
    }
  }
}

export { startTrack, endTrack }