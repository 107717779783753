import React, { useState } from 'react'
import Axios from 'axios'
import { useSelector } from 'react-redux'
import config from '../config'
import { toast } from 'react-toastify'
import { COLORS } from '../styles/theme'
import Amplitude from '../utils/amplitudeHelper'

export default function GoogleSheetForm({ hallName }) {
  const { id, fullName, email } = useSelector((state) => state.auth.currentUser)
  const [ loading, setLoading ] = useState(false)
  const [question, setQuestion] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    Amplitude.submitQuestion({
      id, fullName, email, question, hallName
    })
    Axios.post(`${config.apiUrl}/api/v1/live_question`, {
      // UserID: id,
      // Name: name,
      // Email: email,
      Question: question,
      HallName: hallName
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`
      }
    })
      .then((resp) => {
        toast('We have received your question, thank you!')
        setQuestion('')
        // toggleForm()
        setLoading(false)
      })
      .catch((err) => {
        toast('Something went wrong, pls try again!')
        // toggleForm()
        setLoading(false)
      })
  }

  const handleInput = (e) => setQuestion(e.target.value)

  return (
    <form onSubmit={handleSubmit}>
      <textarea
        value={question}
        onChange={handleInput}
        placeholder="Min 5 characters..."
      ></textarea>
      <button 
        className="btn"
        type="submit"
        disabled={loading || question.length < 5}
      >
        {loading ? 'Submitting...' : 'Submit'}
        <svg width="16" height="13" viewBox="0 0 20 18" >
          <g stroke="none" strokeWidth="1" fill={COLORS.primary} fillRule="evenodd">
            <g transform="translate(-374.000000, -1529.000000)">
              <g transform="translate(100.000000, 1428.000000)">
                <g transform="translate(272.000000, 98.000000)">
                  <g>
                    <polygon fill="none" points="0 0 24 0 24 24 0 24"></polygon>
                    <path d="M3.4,20.4 L20.85,12.92 C21.66,12.57 21.66,11.43 20.85,11.08 L3.4,3.6 C2.74,3.31 2.01,3.8 2.01,4.51 L2,9.12 C2,9.62 2.37,10.05 2.87,10.11 L17,12 L2.87,13.88 C2.37,13.95 2,14.38 2,14.88 L2.01,19.49 C2.01,20.2 2.74,20.69 3.4,20.4 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </form>
  )
}
