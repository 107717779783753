import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import TaglineM from '../../images/backdrops/bannerP.png'
import TaglineD from '../../images/backdrops/bannerL.png'

import { COLORS, SIZES } from '../../styles/theme'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.register.landscape});
  background-repeat: no-repeat;
  background-size: cover;
  background-position:left;
  padding: 0 1.5em;
  padding-bottom: 4.5em;
  padding-top: 2em;
  color: #fff;
  img#banner {
    display: none;
  }
  select.form-input {
    text-align-last: center;
  }
  ${SIZES.mobile} {
    background-image: url(${config.assets.register.portrait});
    padding: 0;
    padding-bottom: 2.5em;
    padding-top: 1em;
    font-size: 0.9em;
    img#banner {
      display: block;
      width: 90%;
    }
    .mobile-hide {
      display: none;
    }
    .col-md-8 {
      flex: none;
      max-width: 100%
    }
  }
  
  .row {
    h6 {
      margin: 16px;
      font-weight: 600;
      ${SIZES.mobile} {
        margin: 8px;
      }
    }
    h6.subtitle {
      font-weight: 500;
      margin: 24px 0;
    }
    #event {
      padding: 2rem 0;
      padding-left: 60px;
      padding-right: 36px;
      align-self: center;
      h4 {
        font-weight: 600;
      }
      h4#info {
        color:#279AAC;
      }
      p {
        margin: 0;
        strong {
          color: #009A47;
        }
      }
      ${SIZES.mobile} {
        text-align: center;
        align-self: flex-start;
        padding: 0;
        margin: 8px auto;
        margin-bottom: 32px;
        h4 {
          margin: 0;
        }
        h4#info {
          color: #FFFFFF;
        }
        p strong {
          color: #AEF3FD;
        }
      }
    }
    .register-form {
      padding: 2.5em 0;

      .form-group {
        width: 100%;
        margin: 8px auto;
        background: #F8F4FF;
        border: 1px solid #AB8EC0;
        border-radius: 25px;
        font-weight: 600;
        text-align: center;
        padding-right: 16px;
        label {
          color: #3C1E5D;
          padding: 0 8px;
          margin: 0 auto;
          padding-right: 0;
          font-size: 13px;
        }
        .required:before {
          content:"* ";
          color: red;
        }
        input[type='tel'],
        .form-input {
          display: block;
          color: #002F62;
          letter-spacing: 1px;
          width: 100%;
          font-weight: 500;
          background: transparent;
          border: none;
          padding: 4px 24px;
          outline: none;
        }
        input[type="text"]:disabled {
          color: gray;
        }
        .react-tel-input {
          color: #002F62;
          input[type='tel'] {
            padding-left: 56px;
            height: 35px;
            box-shadow: none;
          }
          .country-list {
            margin: 0;
          }
          .flag-dropdown {
            background: transparent;
            border: none;
            padding-left: 16px;
            .selected-flag {
              background: transparent;
            }
          }
        }
      }
      #password {
        width: 12px;
        margin: 2px 4px;
      }
    }
  }
  .form-check {
    /* display:flex;
    align-items: flex-start; */
    .form-check-label {
      /* color: ${COLORS.primary}; */
      font-weight: 500;
      font-size: 14px;
      margin-left: 8px;
      a {
        color: #AB8EC0;
        text-decoration: underline;
      }
    }
  }
  
  button.submit-btn {
    background: #CE2440;
    width: 35%;
    color: #fff;
    font-weight: 500;
    font-style: italic;
    border: 2px solid #fff;
    border-radius: 56px;
    display: flex;
    justify-content: space-between;
    padding: 4px 24px;
    margin: 16px 0;
    margin-bottom: 0;
    margin-left: auto;
    font-size: 1em;
    outline: none;
    box-shadow: none;

    ${SIZES.mobile} {
      padding: 4px 24px;
      width: 100%;
    }

    &:disabled {
      cursor: not-allowed;
      background: grey;
      border: solid 1px ${COLORS.primary};
    }

    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function RegisterFormPage() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [salutation, setSalutation] = useState('')
  const [fullName, setFullName] = useState('')
  const [NRIC, setNRIC] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('60')
  const [MMC, setMMC] = useState('')
  const [specialty, setSpecialty] = useState('')
  const [otherSpecialty, setOtherSpecialty] = useState('')
  const [sectorPractice, setSectorPractice] = useState('')
  const [otherSectorPractice, setOtherSectorPractice] = useState('')
  const [placePractice, setPlacePractice] = useState('')
  const [state, setState] = useState("")
  const [invitedVia, setInvitedVia] = useState('')
  const [otherInvitedVia, setOtherInvitedVia] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [agree, setAgree] = useState(false)

  const optionList = [
    'Datuk',
    'Dato',
    'Datin',
    'Prof.',
    'Assoc. Prof',
    'Dr',
    'Mr',
    'Ms',
    'Madam',
  ]
  const stateList = [
    'Perlis',
    'Kedah',
    'Penang',
    'Perak',
    'Selangor',
    'Kuala Lumpur',
    'Negeri Sembilan',
    'Melaka',
    'Johor',
    'Pahang',
    'Kelantan',
    'Terengganu',
    'Sabah',
    'Sarawak',
  ]
  const specialtyList = [
    'Anesthesiologist',
    'Cardiologist',
    'Chest physician',
    'Dermatologist',
    'Endocrinologist',
    'ENT',
    'Family medicine',
    'Gastroenterologist',
    'General physician',
    'General practitioner',
    'General surgeon',
    'Geriatrician',
    'Hematologist',
    'Hepatologist',
    'Infectious disease specialist',
    'Intensivist',
    'Internal medicine',
    'Medical officer',
    'Nephrologist',
    'Neurologist',
    'Obstetrician & gynaecologist',
    'Oncologist',
    'Opthalmologist',
    'Orthopaedic',
    'Paediatrician',
    'Pharmacist',
    'Psychiatrist',
    'Respiratory',
    'Rheumatology',
    'Urologist',
    'Others',
  ]
  const sectorList = [
    'Government Hospital ',
    'Private Hospital',
    'Government Clinic',
    'Private Clinic',
    'Pharmacy',
    'Others',
  ]
  const invitationList = [
    'Medical Representative',
    'Email from Society',
    'Email from DKSH',
    'Others',
  ]

  const handleEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }
  const handleCheckBox = (e) => {
    setAgree(e.target.checked)
  }
  const handleOther = (e) => {
    if (e.target.name === 'specialty') {
      setSpecialty(e.target.value)
      setOtherSpecialty("")
    }
    if (e.target.name === 'sector') {
      setSectorPractice(e.target.value)
      setOtherSectorPractice("")
    }
    if (e.target.name === 'invitation') {
      setInvitedVia(e.target.value)
      setOtherInvitedVia("")
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    let finalSpecialty = specialty
    let finalSector = sectorPractice
    let finalInvited = invitedVia
    if (specialty === 'Others') {
      finalSpecialty = otherSpecialty.trim()
    }
    if (sectorPractice === 'Others') {
      finalSector = otherSectorPractice.trim()
    }
    if (invitedVia === 'Others') {
      finalInvited = otherInvitedVia.trim()
    }
    const options = {
      salutation,
      full_name: fullName.trim(),
      nric_number: NRIC,
      email,
      phone,
      mmc_number: MMC,
      specialty: finalSpecialty,
      sector_of_practice: finalSector,
      state: state,
      place_of_practice: placePractice,
      invited_via: finalInvited,
      password: password.trim(),
      agree,
    }
    Axios.post(`${config.apiUrl}/api/v1/register`, options)
      .then(() => {
        setLoading(false)
        history.push(routes.postRegister)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
    // toast("Registration is now closed.")
  }

  const hasEmptyField = !(
    salutation.length &&
    fullName.length &&
    NRIC.length &&
    email.length &&
    phone.length >= 7 &&
    // MMC.length &&
    specialty.length &&
    ((specialty === 'Others') == (otherSpecialty.length > 0)) &&
    sectorPractice.length &&
    ((sectorPractice === 'Others') == (otherSectorPractice.length > 0)) &&
    placePractice.length &&
    state.length &&
    invitedVia.length &&
    ((invitedVia === 'Others') == (otherInvitedVia.length > 0)) &&
    password.length >= 8 &&
    agree &&
    passwordConfirmation === password
  )

  return (
    <Page>
      <img id="banner" src={TaglineM} alt="event-tagline" className="mx-auto mt-5" />
      <Row id="main" noGutters className="px-4 px-md-0 pt-2 pb-3">
        <Col md={2} className="mobile-hide"></Col>
        <Col md={8}>
          <img width="100%" src={TaglineD} alt="event-tagline" className="mx-auto mobile-hide" />
          <h6 className="subtitle px-md-4">Kindly fill in your details below to complete registration</h6>
          {hasEmptyField && (
            <p className="text-danger m-0 px-md-4">
              * Please fill in the required field to proceed.
            </p>
          )}
          <Row className="register-form px-md-4 py-3" noGutters>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">Salutation</label>
                <select
                  className="form-input"
                  value={salutation}
                  onChange={(e) => setSalutation(e.target.value)}
                >
                  <option value="" disabled selected hidden>-- SELECT --</option>
                  {optionList.map((item, i) => (
                    <option key={`option-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">Full Name (as per NRIC or Passport)</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">NRIC Number</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={NRIC}
                  onChange={(e) => setNRIC(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">Email Address</label>
                <input
                  className="form-input"
                  required
                  type="email"
                  value={email}
                  onChange={handleEmailInput}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">Mobile Number</label>
                <PhoneInput
                  inputProps={{ required: true }}
                  className="form-input"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label>MMC Number</label>
                <input
                  className="form-input"
                  // required
                  type="text"
                  value={MMC}
                  onChange={(e) => setMMC(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">Specialty</label>
                <select
                  className="form-input"
                  value={specialty}
                  name="specialty"
                  onChange={handleOther}
                >
                  <option value="" disabled selected hidden>-- SELECT --</option>
                  {specialtyList.map((item, i) => (
                    <option key={`specialty-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label>Other Specialty, please specify here</label>
                <input
                  className="form-input"
                  type="text"
                  value={otherSpecialty}
                  placeholder={specialty !== 'Others' ? "Not Applicable" : "* Please specify here"}
                  disabled={specialty !== 'Others'}
                  onChange={(e) => setOtherSpecialty(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">Channel of Practice</label>
                <select
                  className="form-input"
                  name="sector"
                  value={sectorPractice}
                  onChange={(e) => setSectorPractice(e.target.value)}
                >
                  <option value="" disabled selected hidden>-- SELECT --</option>
                  {sectorList.map((item, i) => (
                    <option key={`sector-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label>Other Channel of Practice, please specify here</label>
                <input
                  className="form-input"
                  type="text"
                  value={otherSectorPractice}
                  placeholder={sectorPractice !== 'Others' ? "Not Applicable" : "* Please specify here"}
                  disabled={sectorPractice !== 'Others'}
                  onChange={(e) => setOtherSectorPractice(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">Place of Practice</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={placePractice}
                  onChange={(e) => setPlacePractice(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">State</label>
                <select
                  className="form-input"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value="" disabled selected hidden>-- SELECT --</option>
                  {stateList.map((item, i) => (
                    <option key={`state-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
            
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">You are invited via...</label>
                <select
                  className="form-input"
                  value={invitedVia}
                  name="invitation"
                  onChange={handleOther}
                >
                  <option value="" disabled selected hidden>-- SELECT --</option>
                  {invitationList.map((item, i) => (
                    <option key={`invited-${i}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label>Other form of invitation, please specify here</label>
                <input
                  className="form-input"
                  type="text"
                  value={otherInvitedVia}
                  placeholder={invitedVia !== 'Others' ? "Not Applicable" : "* Please specify here"}
                  disabled={invitedVia !== 'Others'}
                  onChange={(e) => setOtherInvitedVia(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">
                  Password (min 8 characters)
                  <svg id="password" viewBox="0 0 48 48">
                    <circle fill="#3C1E5D" cx="24" cy="24" r="21"/>
                    <rect x="22" y="22" fill="#fff" width="4" height="11"/>
                    <circle fill="#fff" cx="24" cy="16.5" r="2.5"/>
                  </svg>
                </label>
                <input
                  className="form-input"
                  required
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="px-md-2">
              <Form.Group>
                <label className="required">
                  Password Confirmation
                </label>
                <input
                  className="form-input"
                  required
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </Form.Group>
            </Col>
            <div className="form-check">
              <p>
                I understand and am aware that DKSH Malaysia Sdn Bhd maintains database(s) on the event/congress where certain personal data about me may be electronically stored, processed and used. I hereby consent and authorize DKSH to collect, store, process and use my personal data including the disclosure and/or transfer of such data to members of the DKSH Group or any of its service provider for review and processing of the data or for being contacted by DKSH, its affiliated companies or their agents for any events, sponsorships or engagements for scientific events.
              </p>
              <input 
                type="checkbox" 
                id="agree-box" 
                onChange={handleCheckBox}
              />
              <label className="form-check-label">
                <span className="text-danger">*</span>
                &nbsp;I agree to the above&nbsp;
                <a href="https://drive.google.com/file/d/1TJguC8FTWCBYlpcIeJMfi5c7Rl_OCvZJ/view" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </a>
                .
              </label>
            </div>
            <div className="w-100 px-md-2">
              <Button
                type="submit"
                className="submit-btn"
                variant="default"
                disabled={hasEmptyField || loading}
                onClick={handleSubmit}
              >
                <div>{loading ? 'Loading...' : 'SUBMIT'}</div>
                <div className="arrow">⇀</div>
              </Button>
            </div>
          </Row>
        </Col>
      </Row>
    </Page>
  )
}
